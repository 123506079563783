import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Layout from '../layouts/index';
import SEO from '../components/seo';
import '../styles/Components/home.scss';
import StarWrapper from '../components/Common/StarWrapper';
import DefaultButton from '../components/Input/DefaultButton';
import RoundedInputField from '../components/Input/RoundedInputField';
import ValidStates from '../components/Input/ValidStates';
import '../styles/Components/contact.scss';
import RoundedTextArea from '../components/Input/RoundedTextArea';
import useFormHooks from '../utils/useFormHooks';

const ContactPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const initialValues = {
        email: {
            value: '',
            message: '',
            regex: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g,
            state: ValidStates.NotSet,
        },
        name: {
            value: '',
            message: '',
            regex: /.+/g,
            state: ValidStates.NotSet,
        },
        company_name: {
            value: '',
            message: '',
            regex: /.+/g,
            state: ValidStates.NotSet,
        },
        message: {
            value: '',
            message: '',
            regex: /.+/g,
            state: ValidStates.NotSet,
        },
        subject: {
            value: '',
            message: '',
            regex: /.+/g,
            state: ValidStates.NotSet,
        },
    };
    const [updateValues, checkState, removeWhiteSpace, values] = useFormHooks(initialValues);
    const encode = (values) => {
        return Object.keys(values)
            .map(
                key => `${encodeURIComponent(key)}=${encodeURIComponent(values[key])}`
            )
            .join('&');
    }
    const handleSubmit = async e => {
        const event = e || window.event;
        ////
        // The following is why microsoft sucks...screw you microsoft
        ////
        if (typeof event.preventDefault === 'function') {
        event.preventDefault();
        }
        if (typeof event.stopPropagation === 'function') {
        event.stopPropagation();
        }
        event.returnValue = false;
        setIsLoading(true);
        try {
            const request = await axios.post('/', encode({
                'form-name': 'contact',
                name: values.name.value,
                company_name: values.company_name.value,
                email: values.email.value,
                subject: values.subject.value,
                message: values.message.value,
            }), {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            })
            setSubmitted(true);
        } catch (error) {
            // Handle Error
            toast.error("Sorry! There was an error submitting the form. Please try again.", {
                position: toast.POSITION.TOP_LEFT
              });
            console.log(error);
        } finally {
            setIsLoading(false);
        }
        
        return false;
    }
    const isValid = values.email.state === ValidStates.Valid
        && values.message.state === ValidStates.Valid
        && values.company_name.state === ValidStates.Valid
        && values.name.state === ValidStates.Valid
        && values.subject.state === ValidStates.Valid; 
    return (
        <Layout>
            <SEO title="Contact" />
            <div className="contact">
                <StarWrapper />
                <section className="contact-container">
                    {!submitted && (
                        <>
                            <h2>Get in touch</h2>
                            <p className="centered">Connect with a Reach representative via our contact form or through messenger, to let us know how we can help.</p>
                            <div className="social-contact">
                                <a className="messenger" href="https://m.me/387599008680694" target="_blank">Messenger</a>
                                <a className="instagram" href="https://www.instagram.com/reachsocialgrowth/" target="_blank"><div className="instagram-inside">Instagram</div></a>
                                <a className="whatsapp" href="https://wa.me/16042568979" target="_blank">WhatsApp</a>
                            </div>
                            <form name="contact" data-netlify="true" data-netlify-honeypot="bot-field" id="contact-form" className="form-container" onSubmit={handleSubmit}>
                                <input type="hidden" name="bot-field" />
                                <p>NAME</p>
                                <RoundedInputField
                                    id="name"
                                    type="text"
                                    value={values.name.value}
                                    placeholder="Name"
                                    checkState={checkState}
                                    callback={e => updateValues(e)}
                                />
                                <p>COMPANY NAME</p>
                                <RoundedInputField
                                    id="company_name"
                                    type="text"
                                    value={values.company_name.value}
                                    placeholder="Company Name"
                                    autoComplete="company_name"
                                    checkState={checkState}
                                    callback={e => updateValues(e)}
                                />
                                <p>WORK EMAIL</p>
                                <RoundedInputField
                                    id="email"
                                    type="text"
                                    value={values.email.value}
                                    placeholder="Work Email"
                                    autoComplete="email"
                                    checkState={checkState}
                                    callback={e => removeWhiteSpace(e)}
                                />
                                <p>SUBJECT</p>
                                <RoundedInputField
                                    id="subject"
                                    type="text"
                                    value={values.subject.value}
                                    placeholder="Subject"
                                    checkState={checkState}
                                    callback={e => updateValues(e)}
                                />
                                <p>MESSAGE</p>
                                <RoundedTextArea
                                    id="message"
                                    type="text"
                                    placeholder="Message"
                                    value={values.message.value}
                                    checkState={checkState}
                                    callback={e => updateValues(e)}
                                />
                                <DefaultButton
                                    text="Submit"
                                    disabled={!isValid}
                                    loading={isLoading}
                                    callback={() => {}}
                                />
                            </form>
                        </>
                    )}
                    {submitted && (
                        <>
                            <h2>Thanks for reaching out!</h2>
                            <p className="centered">We are working on crafting you the perfect response, and will be in touch soon.</p>
                        </>
                    )}
                </section>
            </div>
            <ToastContainer position={toast.POSITION.TOP_RIGHT} />
        </Layout>
    );
};

export default ContactPage;